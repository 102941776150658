.homepageWrapper {
    padding-left: 50px;
}

.contentWrapper {
    padding-top: 10px;
    display: flex;
}

.channelListWrapper {
    flex-basis: 15%;
}

.channelList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.channelName{
    color: white;
    font-weight: 500;
justify-self: center;

}

.channelListItem {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 24px;
    padding: 30px 20px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.channelListItem:hover,
.channelListItem:active,
.channelListItem:focus,
.channelListItem:focus-visible,
.section-2:focus,
.section-2:focus-visible,
.section-2:focus-within {
    /* border-bottom: 2px solid crimson;
    outline: none; */
}

.channelList .focusable:focus-visible .channelListItem,
.channelList .focusable:focus-within .channelListItem,
.channelList .focusable:focus .channelListItem  {
    background-color: #514f4f;
    border-bottom: 2px solid crimson;
    outline: none;
}

.channelList .section-2:focus {
    outline: 2px solid crimson;
    background-color: #514f4f;
}


.channelsView {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 5px;
    height: calc(100vh - 200px);
    width: 100%;
}

.channelsView .focusable {
    border: 2px solid transparent;
    border-radius: 15px; 
}

.channelsView .focusable:focus {
    outline: 2px solid crimson; 
}

.focusable:focus-visible,
.focusable:focus-within,
.focusable:focus {
    outline: none;
}

.channelsView .focusable:focus-visible .channelItem,
.channelsView .focusable:focus-within .channelItem,
.channelsView .focusable:focus .channelItem {
    /* border: 2px solid crimson; */
    outline: none;
}

.channelItem {
    display: block;
    height: auto;
    padding: 5px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 15px;
}

.channelItemImage,
.channelItem img {
    height: 160px;
    width: 160px;
    object-fit: contain;
}

.channelItem:hover,
.channelItem:active,
.channelItem:focus{
    border: 2px solid crimson;
    outline: none;
}

.noResults {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 48px;
    font-weight: 500;
    height: 100%;
    width: 100%;
}